import { mapState } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import TxInlineSvg from '@/components/lib/TxInlineSvg/TxInlineSvg.vue'
import WithBackgroundImagePartial from '@/pages/lib/partials/WithBackgroundImagePartial/WithBackgroundImagePartial.vue'
import { CachedImageModel } from 'src/models'

export default {
  components: {
    TxButton,
    TxInlineSvg,
    WithBackgroundImagePartial
  },
  computed: {
    ...mapState({
      otcContacts: state => state.public.otcContacts
    }),
    items () {
      return [
        {
          itemText: this.i18nSection('otcDescription')
        }
      ]
    }
  },
  methods: {
    getImageUrl (image) {
      const imageModel = CachedImageModel.fromServerModel(image, {
        baseUrl: this.config.publicService.imageUrl
      })
      return imageModel.url
    },
    getContactUrl (url) {
      return url.includes('@')
        ? `mailto:${url}`
        : url
    }
  }
}
